import {getWidth,getHeight, getProcentWidth, getProcentHeight} from '../../../../../assets/stylesVar'

const containerStyles: React.CSSProperties = {
    width:getProcentWidth(1383),
    height:getProcentHeight(560),
    borderRadius:'25px',
    background:'#FBFBFD',
    display:'flex',
    alignItems:'center',
    flexDirection:'column',
    justifyContent:'center'
}

const DatePickerDivStyles :React.CSSProperties = {
    width:getWidth(253),
    height:getHeight(43),
    display:'flex',
    alignItems:'center',
    justifyContent:'space-evenly',
    marginBottom: '3%',
    marginLeft: '70%'
}

export { 
    containerStyles,
    DatePickerDivStyles
  };