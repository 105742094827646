import React, { useState } from 'react';
import { containerQuest, containerStyles, sp_1 } from './patientquestStyle';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSendDataMutation } from '../../../app/services/form/patientQuest';
import { ErrorMessage } from '../../../components/error-message';
import { isErrorWithMessage } from '../../../utils/is-error-with-message';

const PatientQuestForm: React.FC = () => {

    const [validated, setValidated] = useState(false);
    const [sp1T] = useState("Пациент");
    const [formDataQuestPatients, setformDataQuestPatients] = useState  ({ 
                                                                            userId: '',
                                                                            disease: '',
                                                                            duration: '',
                                                                            age: '',
                                                                            gender: 'male',
                                                                            doctorVisit: false,
                                                                            doctorRegistered: false
                                                                         });
    const [error, setError] = useState("");
    const [sendData] = useSendDataMutation();
    const navigate = useNavigate();
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget;

        setformDataQuestPatients((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));


        /**
         * Very Stupid things. But useState doesnt work on Form.Check name = 'gender'
         * Need to change in future ...
         */
        if (name === "gender")
        {   
            formDataQuestPatients.gender = value;
        }

        if (name === "DV")
        {   
            formDataQuestPatients.doctorVisit = value === 'Yes'? true : false;
        }

        if (name === "VDR")
        {   
            formDataQuestPatients.doctorRegistered = value === 'Yes'? true : false;
        }

      };

    const handleSubmit = async (event:React.FormEvent<HTMLFormElement>) => {
        try {

            const form = event.currentTarget;

            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                setValidated(true);
                return;
            }
            
            
            event.preventDefault();
            formDataQuestPatients.userId = localStorage.getItem('userId')||'0';


            await sendData(formDataQuestPatients).unwrap();

            localStorage.setItem('role','Patient');

            navigate('/welcomePage');
            
        }
        catch (error) {
            const maybeError = isErrorWithMessage(error);

            if (maybeError) {
                setError(error.data.message);
            } else {
                setError("Нет соединение до Сервера!");
            }
        }
    }

    const BackToRoleChoose = () => {
        navigate('/form');
    }
    

    return (
        <div className="d-flex align-items-center justify-content-center" style={containerStyles}>
             <div style={containerQuest}>
                <div style={sp_1}>
                    {sp1T}
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <Form noValidate validated={validated} id='123'
                     onSubmit={handleSubmit}
                     >
                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="validationCustom01">
                        <Form.Control
                            required
                            type="text"
                            placeholder="Заболевание"
                            defaultValue=""
                            name='disease'
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                           Пожалуйста укажите данные!
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Row>
                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Control
                            required
                            type="number"
                            placeholder="Сколько болеете"
                            defaultValue=""
                            inputMode="numeric"
                            pattern="[0-9]*"
                            name='duration'
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                           Пожалуйста укажите данные!
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Row>
                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Control
                            required
                            type="number"
                            placeholder="Ваш Возраст"
                            defaultValue=""
                            inputMode="numeric"
                            pattern="[0-9]*"
                            name='age'
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                           Пожалуйста укажите данные!
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Label> Укажите ваш пол </Form.Label>
                        <div className='d-flex align-items-center justify-content-around'>
                        <Form.Check
                            label="Мужской"
                            name="gender"
                            type='radio'
                            id="GenOption"
                            value='male'
                            onChange={handleInputChange}
                            defaultChecked
                        />
                        <Form.Check
                            label="Женский"
                            name="gender"
                            type='radio'
                            id="GenOption"
                            value='female'
                            onChange={handleInputChange}
                        />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <Form.Label> Наблюдаетесь у врача? </Form.Label>
                        <div className='d-flex align-items-center justify-content-around'>
                        <Form.Check
                            label="Да"
                            name="DV"
                            type='radio'
                            id="VDOption"
                            value='Yes'
                            onChange={handleInputChange}
                            defaultChecked
                        />
                        <Form.Check
                            label="Нет"
                            name="DV"
                            type='radio'
                            id="VDOption"
                            value='No'
                            onChange={handleInputChange}
                        />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <Form.Label> Врач зарегистрирован в Vitaent? </Form.Label>
                        <div className='d-flex align-items-center justify-content-around'>
                        <Form.Check
                            label="Да"
                            name="VDR"
                            type='radio'
                            id="VDROption"
                            value='Yes'
                            onChange={handleInputChange}
                            
                        />
                        <Form.Check
                            label="Нет"
                            name="VDR"
                            type='radio'
                            id="VDROption"
                            value='No'
                            onChange={handleInputChange}
                            defaultChecked
                        />
                        </div>
                    </Row>
                    <div className='1_b d-flex justify-content-around'>
                    <Button 
                        variant="primary"
                        style={{width:'160px'}}
                        onClick={BackToRoleChoose}
                    >
                         Назад
                    </Button>

                    <Button 
                        type="submit"
                        variant="primary"
                        style={{width:'160px'}}
                    >   
                        Сохранить 
                    </Button>
                    </div>
                    </Form>
                </div>
                <ErrorMessage message={error} />
             </div>
        </div>
    )
};


export default PatientQuestForm;