import React from 'react';
import { Routes, Route} from 'react-router-dom';
import { Paths } from '../patient/PatientPaths';
import NavbarPatients from './navbar/navbar';
import SidebarPatients from './sidebar/sidebar';
import MainContent from './pages/main/MainContent';
import JournalFormPatient from './modules/journal/main/journal';
import JournalNewForm from './modules/journal/new/journalnew';
import JournalEntryForm from './modules/journal/entry/journalentry';
import Emotions from './modules/emotions/main/main';
import EmotionsNewRec from './modules/emotions/entry/entry';

const AppPatients: React.FC = () => {

  return (
    <div>
      <NavbarPatients />
      <div className='d-flex'>
      <SidebarPatients/>
      <Routes>
        <Route path={Paths.main} element={<MainContent />} />
        <Route path={Paths.Journal} element={<JournalFormPatient/>}>
        </Route>
        <Route path={Paths.JournalTitleCardRecord} element={<JournalNewForm/>} />
        <Route path={Paths.JournalEntryRecord} element = {<JournalEntryForm/>}/>
        <Route path={Paths.EmotionsMain} element={<Emotions/>} />
        <Route path={Paths.EmotionEntryRec} element={<EmotionsNewRec/>} />
      </Routes>
      </div>
    </div>
  );
};
export default AppPatients;