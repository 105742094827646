import { api } from '../../../api'

export const typesNoteApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTypeNotes: builder.query<Response,void>({
            query: () => ({
              url: '/ut/typeNotes',
              method: 'GET'
            }),
        }),
    })
});


export const { useGetTypeNotesQuery } = typesNoteApi;