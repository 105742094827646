import { getHeight,
         getProcentHeight,
         getProcentWidth,
         getWidth } from "../../../../../../assets/stylesVar";

const containerStyles: React.CSSProperties = {
  width:getProcentWidth(1383),
  height:getProcentHeight(560),
  borderRadius: '25px',
  background: '#FBFBFD',
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center'
};

const DatePickerDivStyles: React.CSSProperties = {
  width: getWidth(253),
  height: getHeight(43),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  marginBottom: '3%',
  marginLeft: '50%',
};

const CardStyles: React.CSSProperties = {
  borderRadius:'25px',
  background:'#f5f5f7',
  borderColor:'#C8C8CC',
  borderWidth:'1px',
  borderStyle:'solid',
  boxShadow: 'none',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  minWidth:getProcentWidth(150),
  minHeight:getProcentHeight(280),
};

const ButtonNewRecStyle: React.CSSProperties = {
  backgroundColor:'#336FEE',
  borderRadius:'10px',
  width: getProcentWidth(138),
  height:getProcentHeight(36),
  position:'relative',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  color:'#FFFFFF',
  textDecoration:'none'
}

export { containerStyles,
         DatePickerDivStyles,
         CardStyles,
         ButtonNewRecStyle
        };