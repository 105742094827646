import React, { useState } from 'react';
import { containerStyles, containerQuest, sp_1 } from '../doctorquest/doctorquestStyle';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSendDDataMutation } from '../../../app/services/form/doctorQuest';
import { ErrorMessage } from '../../../components/error-message';
import { isErrorWithMessage } from '../../../utils/is-error-with-message';

const DoctorQuestForm : React.FC = () => {

    const [sp1T] = useState("Врач");
    const [validated, setValidated] = useState(false);
    const [formDataQuestDoctors, setformDataQuestPatients] = useState  ({ 
        userId: '',
        spec: '',
        exp: '',
        age: '',
        gender: 'male'
     });

    const [error, setError] = useState("");

    const navigate = useNavigate();

    const [sendData] = useSendDDataMutation();

    const handleSubmit = async (event:React.FormEvent<HTMLFormElement>) => {
        try {

            const form = event.currentTarget;

            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                setValidated(true);
                return;
            }

            event.preventDefault();

            formDataQuestDoctors.userId = localStorage.getItem('userId')||'0';

            await sendData(formDataQuestDoctors).unwrap();

            localStorage.setItem('role','Doctor');

            navigate('/welcomePage');

        } catch (error)
        {

            const maybeError = isErrorWithMessage(error);

            if (maybeError) {
                setError(error.data.message);
            } else {
                setError("Нет соединение до Сервера!");
            }

        }
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
        const { name, value } = e.currentTarget;

        setformDataQuestPatients((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
  
    };

    const BackToRoleChoose = () => {
        navigate('/form');
    }
    

    return (
        <div className="d-flex align-items-center justify-content-center" style={containerStyles}>
            <div style={containerQuest}>
                <div style={sp_1}>
                    {sp1T}
                </div>

                <div className="d-flex align-items-center justify-content-center">
                    <Form noValidate validated={validated} id='123'
                         onSubmit={handleSubmit}
                         className='d-flex align-items-center flex-column justify-content-around'
                         style={{height: '400px'}}
                     >
                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="validationCustom01">
                        <Form.Control
                            required
                            type="text"
                            placeholder="Квалификация и специализация"
                            defaultValue=""
                            name='spec'
                            onChange={handleInputChange}
                            style={{width:'358px'}}
                        />
                        <Form.Control.Feedback type="invalid">
                           Пожалуйста укажите данные!
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Row>
                    
                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Control
                            required
                            type="text"
                            placeholder="Стаж работы в медицинской практике"
                            name='exp'
                            onChange={handleInputChange}
                            style={{width:'358px'}}
                        />
                        <Form.Control.Feedback type="invalid">
                           Пожалуйста укажите данные!
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Row>

                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="validationCustom03">
                        <Form.Control
                            required
                            type="text"
                            placeholder="Ваш возраст"
                            defaultValue=""
                            name='age'
                            onChange={handleInputChange}
                            style={{width:'358px'}}
                        />
                        <Form.Control.Feedback type="invalid">
                           Пожалуйста укажите данные!
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Row>

                    <FormGroup className="row">
                    <Form.Label> Укажите ваш пол </Form.Label>
                        <FormGroup>
                            <div className='d-flex align-items-center justify-content-around'>
                            <Form.Check
                            inline
                            id = '1'
                            label="Мужской"
                            type="radio"
                            name="gender"
                            value="male"
                            checked={formDataQuestDoctors.gender === "male"}
                            onChange={handleInputChange}
                            />
                            <Form.Check
                            inline
                            id = '2'
                            label="Женский"
                            type="radio"
                            name="gender"
                            value="female"
                            checked={formDataQuestDoctors.gender === "female"}
                            onChange={handleInputChange}
                            />
                            </div>
                        </FormGroup>
                    </FormGroup>

                    <div className='1_b d-flex justify-content-around' style={{width:'430px'}}>
                    <Button 
                        variant="primary"
                        style={{width:'160px'}}
                        onClick={BackToRoleChoose}
                    >
                         Назад
                    </Button>
                    <Button 
                        type="submit"
                        variant="primary"
                        style={{width:'160px'}}
                    >   
                        Сохранить 
                    </Button>
                    </div>
                    </Form>
                </div>
                <ErrorMessage message={error} />
            </div>
        </div>
    );
};

export default DoctorQuestForm;