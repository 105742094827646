import { Button, Card, CardContent, Checkbox, Grid, List, ListItemButton, ListItemText, Slider, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {CardStylesF, CardStylesS, CardStylesSButton, containerStyles } from './entryStyles';
import { 
         useGetDictDailyEmotionSectQuery,
         useGetDictDailyEmotionStateQuery,
         IDictEmotionsState,
         IDictEmotionsSect,
         IPatientEmotion,
         useGetPatientEmotionsbyDayMutation,
         IPatientEmotionArray,
         useSaveEmotionsRecMutation
        } from '../../../../../../app/services/patient/emotions/emotions';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import { isErrorWithMessage } from '../../../../../../utils/is-error-with-message';
import { ErrorMessage } from '../../../../../../components/error-message';


const CustomizedCheck = styled(Checkbox)`
  &.Mui-disabled {
    color:  #1976d2 !important;
  }
`;

const EmotionsNewRec: React.FC = () => {

    const is720p = useMediaQuery('(max-width:1280px)');
    const numRows = is720p ? 8 : 16;

    const navigate = useNavigate();
    const location = useLocation();
    const pDate = location.state && typeof location.state === 'object' && 'pDate' in location.state ? location.state.pDate : '';
    const [error, setError] = useState("");
    

    const [EmotionsSect, setEmotionsSect] = useState<IDictEmotionsSect[] | undefined>();
    const [EmotionState, setEmotionState] = useState<IDictEmotionsState[] | undefined>();

    const transformApiResponse = (apiResponse: any): IPatientEmotionArray => {
        const { id, UserId, RecDate, patientEmotion } = apiResponse[0];
    
        const transformedPatientEmotion = patientEmotion.map((emotion: any) => ({
            patientEmotion: {
                id: emotion.id,
                Collection: emotion.Collection,
                DailySection: emotion.DailySection,
            },
            patientEmotionsStateVal: {
                id: emotion.patientEmotionsStateVal[0].id,
                Collection: emotion.patientEmotionsStateVal[0].Collection,
                StateVal: emotion.patientEmotionsStateVal[0].StateVal,
            },
            patientEmotionMemo: {
                id: emotion.patientEmotionsStateMemo.length > 0 ? emotion.patientEmotionsStateMemo[0].id : 0,
                Collection: emotion.patientEmotionsStateMemo.length > 0 ? emotion.patientEmotionsStateMemo[0].Collection : 0,
                Memo: emotion.patientEmotionsStateMemo.length > 0 ? emotion.patientEmotionsStateMemo[0].Memo : ''
            }
        }));
    
        return {
            ArrayIndex: {
                id,
                UserId,
                RecDate
            },
            patientEmotion: transformedPatientEmotion,
        };
    };

    const [PatientEmotionRec] = useState<IPatientEmotion>({
        id: 0,
        UserId: parseInt(localStorage.getItem('userId')?? ''),
        RecDate: pDate
    });

    const [Emotions, setEmotions] = useState<IPatientEmotionArray>({ArrayIndex: PatientEmotionRec,
        patientEmotion: [
            {
                patientEmotion: {
                    id: -1,
                    Collection: 0,
                    DailySection: 1,
                },
                patientEmotionsStateVal: {
                    id: -1,
                    Collection: 0,
                    StateVal: 4
                },
                patientEmotionMemo: {
                    id: -1,
                    Collection: 0,
                    Memo: ''
                }
            },
            {
                patientEmotion: {
                    id: -1,
                    Collection: 0,
                    DailySection: 2,
                },
                patientEmotionsStateVal: {
                    id: -1,
                    Collection: 0,
                    StateVal: 4
                },
                patientEmotionMemo: {
                    id: -1,
                    Collection: 0,
                    Memo: ''
                }
            },
            {
                patientEmotion: {
                    id: -1,
                    Collection: 0,
                    DailySection: 3,
                },
                patientEmotionsStateVal: {
                    id: -1,
                    Collection: 0,
                    StateVal: 4
                },
                patientEmotionMemo: {
                    id: -1,
                    Collection: 0,
                    Memo: ''
                }
            },
        ]});

    const getDailyEmotionSect = useGetDictDailyEmotionSectQuery();
    const getDailyEmotionState  = useGetDictDailyEmotionStateQuery();
    const [getDailyEmotionByDay] = useGetPatientEmotionsbyDayMutation();
    const [saveEmotionsRec] = useSaveEmotionsRecMutation();

    useEffect(() => {
        const fGetPatientEmotions = async () => {
            try {
                    const responseData = await getDailyEmotionByDay(PatientEmotionRec).unwrap();

                    if (Object.keys(responseData).length === 0) {
                      } else {
                        const transformedData = transformApiResponse(responseData);
                        /*setEmotions(JSON.parse(JSON.stringify(responseData)));*/
                        setEmotions(transformedData);
                      }
                        
            } catch (error) {
                console.log(error);
            }
        };

        fGetPatientEmotions();
    }, [PatientEmotionRec, getDailyEmotionByDay]);

    useEffect(() => {

        if (!pDate) {
            navigate("/main/P/Emotions/");
        }
      }, [navigate, pDate]);

    useEffect(() => {
        const fGetDailyEmotionSect = async () => {
            try {
                if (getDailyEmotionSect.isSuccess) {
                    const responseData = JSON.parse(getDailyEmotionSect.data.toString()); 
                    setEmotionsSect(responseData);
                }
            } catch (error) {
                console.log(error);
            }
        };

        const fGetDailyEmotionSstate = async () => {
            try {
                if (getDailyEmotionState.isSuccess) {
                    const responseData = JSON.parse(getDailyEmotionState.data.toString()); 
                    setEmotionState(responseData);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fGetDailyEmotionSect();
        fGetDailyEmotionSstate();
    }, [getDailyEmotionSect.data, getDailyEmotionSect.isSuccess, getDailyEmotionState.data, getDailyEmotionState.isSuccess]);

    const [selectedIndex, setSelectedIndex] = useState(1);

      
    const handleListItemClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      index: number,
    ) => {
      setSelectedIndex(index);
      
    };
  

    const valuetext = (value: number): string => {
        const valueName = EmotionState?.find((item) => item.Value === value);
        return valueName?.Name ?? '';
    };
    
    const getSelectSection = (index: number) : boolean => {
        
        const findIndex = Emotions.patientEmotion.find(item => item.patientEmotion.DailySection === index+1);
        
        return findIndex?.patientEmotionsStateVal.id !== -1 ?? false;
    };

    const [sliderValue,setSliderValue] = useState(4);
    const [memoValue,setMemoValue] = useState('');

    const updateEmotionStateVal = (newValue: number) => {
        setEmotions((prevEmotions) => {
            const updatedEmotions = { ...prevEmotions };
            const emotionToUpdate = updatedEmotions.patientEmotion.find((item) => item.patientEmotion.DailySection === selectedIndex + 1);
            
            if (emotionToUpdate) {
                if (emotionToUpdate.patientEmotionsStateVal.StateVal !== newValue) {
                    emotionToUpdate.patientEmotionsStateVal.StateVal = newValue;
                }
            }
    
            return updatedEmotions;
        });
    };
    
    const updateEmotionMemo = (newValue: string) => {
        setEmotions((prevEmotions) => {
            const updatedEmotions = { ...prevEmotions };
            const emotionToUpdate = updatedEmotions.patientEmotion.find((item) => item.patientEmotion.DailySection === selectedIndex + 1);
            
            if (emotionToUpdate) {
                if (emotionToUpdate.patientEmotionMemo.Memo !== newValue) {
                    emotionToUpdate.patientEmotionMemo.Memo = newValue;
                }
            }
    
            return updatedEmotions;
        });
    };

    const handleChangeSlider = (event: Event, newValue: number | number[]) => {
            setSliderValue(newValue as number);
            updateEmotionStateVal(newValue as number)
        
    };

    const handleChangeMemo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMemoValue(event.target.value);
        updateEmotionMemo(event.target.value as string);
    };

    useEffect(() => {
        const MemoValue = () : string =>{
            const value = Emotions.patientEmotion.find(item => item.patientEmotion.DailySection === selectedIndex+1);

            return value?.patientEmotionMemo.Memo ?? '';
        };
        const SliderValue = (): number => {
            const value = Emotions.patientEmotion.find(item => item.patientEmotion.DailySection === selectedIndex+1);
            return value?.patientEmotionsStateVal.StateVal ?? 4;
        };
        
        setSliderValue(SliderValue);
        setMemoValue(MemoValue);
      }, [Emotions.patientEmotion, selectedIndex]);

    const SaveEmotionArray = async () => {
        try {
            await saveEmotionsRec(Emotions).unwrap();
            navigate("/main/P/Emotions/");
        } catch {
            const maybeError = isErrorWithMessage(error);

            if (maybeError) {
                setError(error.data.message);
            } else {
                setError("Нет соединение до Сервера!");
            }
        }
    };
    const ButtonBack: React.FC = () => {

        const BackSelectList = () => {
            setSelectedIndex(selectedIndex-1);
        }

        let l_s_state_name = EmotionsSect?.find((item) => item.id === selectedIndex)?.Name ?? '';

        l_s_state_name = l_s_state_name.replace(' состояние','');

        if (l_s_state_name.length > 0){
            return (
                <Button  size="medium" onClick={BackSelectList}>
                    <ArrowBackIosIcon/> {l_s_state_name}
                </Button>
            );
        }else{
            return (
                <div/>
            );
        }
    };

    const ButtonForward: React.FC = () => {

        const ForwardSelectList = () => {
            setSelectedIndex(selectedIndex+1);
        }

        let l_s_state_name = EmotionsSect?.find((item) => item.id === selectedIndex+2)?.Name ?? '';

        l_s_state_name = l_s_state_name.replace(' состояние','');

        if (l_s_state_name.length > 0){
            return (
                <Button  size="medium" onClick={ForwardSelectList}>
                    {l_s_state_name} <ArrowForwardIosIcon/>
                </Button>
            );
        }else{
            return (
                <Button variant="outlined" onClick={SaveEmotionArray}>
                     Сохранить
                </Button>

            );
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ backgroundColor: '#eeedef', height: '100%', width: '100%' }}
            >
                <Card style={containerStyles}>
                <Grid   container 
                        style={{ height: '90vh', padding: 50 }}
                >
                    <Grid   container
                            columns={2}
                            spacing={15}
                            direction={'row'}
                            display={'flex'}
                            style={{ width: '100%',flexWrap: 'nowrap' }}
                    >
                        <Grid item>
                            <Card sx={CardStylesF}>
                                <CardContent sx={{
                                                    padding:"0px",
                                                    '&:last-child': { pb: 0 },
                                                    display:"flex",
                                                    justifyContent:"space-evenly",
                                                    flexDirection:"column",
                                                    height:'280px',
                                                    width:'100%',
                                                    alignItems:'center',
                                                    backgroundColor: '#FBFBFD'
                                                }}>
                                <Typography variant="h6" 
                                            component="div" 
                                            justifyContent="center" 
                                            display="flex"
                                            sx={{marginTop: "10px"}}
                                >
                                    Расписание за {pDate}
                                </Typography>
                                    <List sx={{ width: '100%',
                                                bgcolor: '#FBFBFD'
                                             }}>

                                    {EmotionsSect?.map((value,index) => (
                                        <ListItemButton
                                            key={index}
                                            disableGutters
                                            divider
                                            selected={selectedIndex === index}
                                            onClick={(event) => handleListItemClick(event, index)}
                                            
                                        >
                                        <ListItemText   primary={`${value.Name}`}
                                                        sx={{marginLeft: "20px"}} 
                                        />
                                        <CustomizedCheck 
                                            checked={getSelectSection(index)}
                                            disabled={true}
                                            disableRipple = {true}
                                        />
                                        </ListItemButton>
                                    ))}

                                    </List>
                                </CardContent>
                                <ErrorMessage message={error} />
                            </Card>
                        </Grid>
                        <Grid   item
                                display={'flex'}
                                columns={2}
                        >
                            <Card sx={CardStylesS}>
                                <Typography variant="h6" 
                                            component="div" 
                                            justifyContent="center" 
                                            display="flex"
                                >
                                    Ваше состояние
                                </Typography>
                                <Slider
                                    aria-label="EmotionsScale"
                                    defaultValue={4}
                                    getAriaValueText={valuetext}
                                    valueLabelFormat={valuetext}
                                    valueLabelDisplay="on"
                                    shiftStep={1}
                                    step={1}
                                    value={sliderValue}
                                    onChange={handleChangeSlider}
                                    marks
                                    min={1}
                                    max={8}
                                    sx={{width:'350px'}}
                                />
                                <Card sx={CardStylesSButton}>
                                    <ButtonBack/>
                                    <ButtonForward/>
                                </Card>
                            </Card>
                            <Card sx={CardStylesS}>
                                Дополнительная сведения
                                
                                <CardContent  sx={{width:'350px'}}>
                                <TextField
                                    id="EntryId"
                                    label="Заметка"
                                    multiline
                                    fullWidth
                                    value={memoValue}
                                    onChange={handleChangeMemo}
                                    rows={numRows}
                                    InputProps={{
                                        style: {
                                            borderRadius: '25px',
                                            color: '#838384',
                                            backgroundColor: '#F5F5F7',
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderStyle: 'none'
                                        }
                                    }}
                                />
                                </CardContent>
                            </Card>
                        </Grid>
                        
                    </Grid>
                </Grid>
                </Card>
            </div>
        </div>
    )
}

export default EmotionsNewRec;