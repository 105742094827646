import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation} from 'react-router-dom';
import { BackStyle, ButtonNewRecStyle, TextInsertRecDiv, TextMainStyle, TimePickStyles, containerStyles } from './journalentryStyles';
import { ReactComponent as ArrowSvg } from '../../../../../../assets/img/tools/back/Arrow1.svg';
import TimePickerForm from '../../../../../../components/TimePicker/TimePicker';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, useMediaQuery } from '@mui/material';
import { useGetTypeNotesQuery } from '../../../../../../app/services/patient/journal/journalentry/typenotes';
import { Button } from 'react-bootstrap';
import { PatientNotes } from ".prisma/client";
import dayjs, { Dayjs } from 'dayjs';
import { isErrorWithMessage } from '../../../../../../utils/is-error-with-message';
import { ErrorMessage } from '../../../../../../components/error-message';
import { useGetJournalRecByIdMutation } from '../../../../../../app/services/patient/journal/journal';
import { useSaveJournalRecByIdMutation } from '../../../../../../app/services/patient/journal/journal';



interface iTypeNote {
    id: number;
    Code: string;
    Name: string;
    Explain: string;
}


interface RequestParams {
    userId?: string;
    recDate?: Date;
    Label?: string;
    Entry?: string;
    TypeNoteId?: number;
    NoteId?: number;
}

export type PatientNotesData = Omit<PatientNotes,"createdAt" | "updatedAt" | "id">

const JournalEntryForm: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [textInputLabel, setTextInputLabel] = useState('');
    const [textInputEntry, setTextInputEntry] = useState('');
    const [idTypeNote, setidTypeNote] = useState('');
    const [helperTypeNote, setHelperTypeNote] = useState('Выберите тип записи');
    const [error, setError] = useState("");
    const [, setTimeCheck] = useState<Dayjs | null>(dayjs());

    const handleTime = (data: Dayjs | null) => {
        setTimeCheck(data);
    };

    const [rData, setrData] = useState<iTypeNote[] | undefined>();
    const getTypeNotesQuery = useGetTypeNotesQuery();
    const is720p = useMediaQuery('(max-width:1280px)');
    const numRows = is720p ? 8 : 16;
    const [getJournalRecById] = useGetJournalRecByIdMutation();
    const [saveJournalRecById] = useSaveJournalRecByIdMutation();

    const saveNoteRec = async () => {
        try {
            
            const noteRecUpd : RequestParams = {
                TypeNoteId: parseInt(idTypeNote),
                Label: textInputLabel,
                Entry: textInputEntry,
                NoteId: parseInt(location.state.pRecId)
            };

            const response = await saveJournalRecById(noteRecUpd);

            if (response)
            {navigate("/main/P/Journal/")}
            
        } catch (error) {
            const maybeError = isErrorWithMessage(error);
            if (maybeError) {
                setError(error.data.message);
            } else {
                setError("Нет соединения с сервером!");
            }
        }
    };

    const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextInputLabel(event.target.value);
    };

    const handleEntryInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextInputEntry(event.target.value);
    };

    useEffect(() => {
        const fGetTypeNotes = async () => {
            try {
                if (getTypeNotesQuery.isSuccess) {
                    const responseData = JSON.parse(getTypeNotesQuery.data.toString());
                    setrData(responseData);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fGetTypeNotes();
    }, [getTypeNotesQuery.data, getTypeNotesQuery.isSuccess]);

    useEffect(() => {
        const fGetJournalRecById = async () => {
            try {
                let lData;
                const response = await getJournalRecById({ noteId: location.state.pRecId }).unwrap();

                if (response) {
                    lData = response;
                    setTextInputLabel(lData.Label || '');
                    setTextInputEntry(lData.Notes || '');
                    const selectedId = lData.TypeNote;
                    const selectedTypeNote = rData?.find(item => item.id === selectedId);
                    if (selectedTypeNote) {
                        setidTypeNote(selectedTypeNote.id.toString());
                        setHelperTypeNote(selectedTypeNote.Explain);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (rData) {
            fGetJournalRecById();
        }
    },[rData, getJournalRecById, location.state.pRecId]);

    const handleChangeTypeNote = (event: SelectChangeEvent) => {
        const selectedId = parseInt(event.target.value, 10);
        const selectedTypeNote = rData?.find(item => item.id === selectedId);
        if (selectedTypeNote) {
            setidTypeNote(selectedTypeNote.id.toString());
            setHelperTypeNote(selectedTypeNote.Explain);
        }
    };

    return (
        <div className='d-flex align-items-center justify-content-center' style={{ width: '100%', backgroundColor: '#eeedef' }}>
            <div style={containerStyles}>
                <div style={TextMainStyle}>
                    Журнал
                </div>
                <div className='d-flex align-items-center' style={BackStyle} onClick={() => navigate(-1)}>
                    <ArrowSvg />
                    <div style={{ color: '#336FEE', marginLeft: '10px' }}>
                        Назад
                    </div>
                </div>
                <div style={TimePickStyles}>
                    <TimePickerForm onTime={handleTime}/>
                </div>
                <div style={TextInsertRecDiv}>
                    <div style={{ position: 'relative', right: '10%' }}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-helper-label">Тип Записи</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Тип Записи"
                                value={idTypeNote}
                                onChange={handleChangeTypeNote}
                                style={{
                                        borderRadius: '15px',
                                        backgroundColor: '#F5F5F7',
                                        width:'252px'
                                    }}
                            >
                                {
                                    rData?.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>{item.Name}</MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText>{helperTypeNote}</FormHelperText>
                        </FormControl>
                    </div>
                    <div style={{ width: '100%' }}>
                        <TextField
                            id="LabelId"
                            label="Заголовок"
                            multiline
                            value={textInputLabel}
                            onChange={handleTextInputChange}
                            fullWidth
                            margin='normal'
                            InputProps={{
                                style: {
                                    width: '100%',
                                    borderRadius: '15px',
                                    color: '#838384',
                                    backgroundColor: '#F5F5F7',
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderStyle: 'none'
                                },
                                sx: { height: '48px' }
                            }}
                        />
                        <TextField
                            id="EntryId"
                            label="Запись"
                            multiline
                            value={textInputEntry}
                            onChange={handleEntryInputChange}
                            fullWidth
                            rows={numRows}
                            InputProps={{
                                style: {
                                    width: '100%',
                                    borderRadius: '25px',
                                    color: '#838384',
                                    backgroundColor: '#F5F5F7',
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderStyle: 'none'
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="d-flex flex-row-reverse" style={{width:'100%'}}>
                    <Button style={ButtonNewRecStyle} onClick={saveNoteRec}>
                            Сохранить
                    </Button>
                </div>
                <ErrorMessage message={error} />
            </div>
        </div>
    );
}

export default JournalEntryForm;
