import React, { useState } from 'react';
import { DatePickerDivStyles, containerStyles } from './mainStyles';
import MainCardForm from '../components/Card/card'
import WeekPicker from '../../../../../components/DatePickerWeekly/DatePickerWeekly';
import dayjs, { Dayjs } from 'dayjs';


const MainFormPatient: React.FC = () => {

    const [dataCheck,setDataCheck] = useState<Dayjs|null>(dayjs());

    const handleData = (data: Dayjs|null) => {
        setDataCheck(data);
      };

    return(
        <div className='d-flex align-items-center justify-content-center' 
             style={{backgroundColor:'#eeedef',height:'100%',width:'100%'}}>
        <div style={containerStyles}>
            <div style={DatePickerDivStyles}>
                <WeekPicker onData={handleData}/>
            </div>
            <MainCardForm dataCheck={dataCheck}/>
        </div>
        </div>
    );
};

export default MainFormPatient