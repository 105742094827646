import React from 'react';
import { ReactComponent as DeleteSvgComponentNotHover } from '../../select/Delete.svg';
import { ReactComponent as DeleteSvgComponentHover } from '../../select/DeleteHover.svg';

const DeleteSvg: React.FC<{ width?: string; height?: string; type?: string }> = ({ width = "18", height = "18", type = 'black' }) => {
    if (type === 'black') {
        return <DeleteSvgComponentNotHover width={width} height={height} />;
    } else if (type === 'hover') {
        return <DeleteSvgComponentHover width={width} height={height} />;
    }
    return null;
};

export default DeleteSvg;