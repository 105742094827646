import React, { useEffect, useState } from 'react';
import { containerStyles,
         containerSmallStylesNotFocused,
         containerSmallStylesFocused, 
         ButtonChangeStyle,
         CurrentDateStyleBot,
         CurrentDateStyleTop,
         ItemDateStyleCurrent,
         ItemDateStyle,
         CompletionStyle,
         CompletionStyleCurrent,
         dayOfWeekStyle,
         dayOfWeekStyleCurrent} from './cardStyles';
import { Button } from 'react-bootstrap';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru'

interface iDataCheck{
    dataCheck: Dayjs|null;
}

interface iDataCard {
    dayOfWeek: String,
    date: String,
    completion: String,
    currentDay: Boolean
}

const MainCardForm: React.FC<iDataCheck> = ({dataCheck}) => {

    
    const [activeIndex, setActiveIndex] = useState(-1);
    const [daysWeekData,setDaysWeekData] = useState<iDataCard[]>([]);

    const CalcCardData = (): iDataCard[] => {
    
    const data: iDataCard[] = [];
        if (dataCheck) {
            var lData : Dayjs = dataCheck?.locale('ru').endOf('week');
            let i = dataCheck?.locale('ru').startOf('week');

            while (i && i.isBefore(lData)) {
                
                data.push({
                    dayOfWeek: i.locale('ru').format('dddd').charAt(0).toUpperCase() + i.locale('ru').format('dddd').slice(1),
                    date: i.locale('ru').format('DD.MM'),
                    completion: (100 - (i.day() * 10)).toString(),
                    currentDay: (i.format('DD.MM.YYYY') === dayjs().locale('ru').format('DD.MM.YYYY'))
                });

                i = i.add(1, 'day');
            }
        }
        return data;
    };

    // Обновление данных при изменении value
    useEffect(() => {
        let DataCardCalc=CalcCardData();
        setDaysWeekData(DataCardCalc);
    // eslint-disable-next-line
    }, [dataCheck]);

    return (
        <div className='d-flex justify-content-evenly' style={{width:'100%'}}>
        {daysWeekData.map((item, index) => (
            <div 
                key={index}
                style=  {   
                            containerStyles
                        }
                onMouseEnter={() => setActiveIndex(index)}
                onMouseLeave={() => setActiveIndex(-1)}
            >
                <div style= {
                                item.currentDay === false ? (activeIndex === index ? containerSmallStylesFocused : containerSmallStylesNotFocused) : (activeIndex === index ? CurrentDateStyleBot : CurrentDateStyleTop)
                            }>
                    { item.currentDay === true? <div style={{color:'#FFFFFF'}}>Текущий день</div>:null}
                    <div style={item.currentDay === true? ItemDateStyleCurrent : ItemDateStyle}>{item.date}</div>
                    <div className='d-flex flex-column align-items-center'>
                        <div style={item.currentDay === true? dayOfWeekStyleCurrent : dayOfWeekStyle}>{item.dayOfWeek}</div>
                        <div style={item.currentDay === true? CompletionStyleCurrent : CompletionStyle}>Заполнено {item.completion}%</div>
                    </div>
                </div>
                <Button type='button' style={ButtonChangeStyle}> Изменить </Button>    
            </div>
        ))}
        </div>
    )
};

export default MainCardForm;
