import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { ReactComponent as Logo} from '../../../../assets/img/LogoMain.svg';
import { ReactComponent as LogoUser} from '../../../../assets/img/user-octagon.svg';
import { Paths } from '../PatientPaths';
import { NavLink } from 'react-router-dom';
import { containerStyles } from './navbarStyles';

const NavbarPatients: React.FC = () => {

    const login = localStorage.getItem('login');

    return (
        <Navbar  data-bs-theme="light" style={containerStyles}>
          <Navbar.Brand style={{marginLeft:'50px'}}><Logo /></Navbar.Brand>
          <Nav className="me-auto">
          </Nav>
          <Nav className="justify-content-end" activeKey="/home"style={{marginRight:'100px'}}>
            <Nav.Item className='d-flex align-items-center justify-content-between' 
            >
              <NavLink style={{
                                fontSize:'24px',
                                textDecoration:'none',
                                color:'#333333',
                                
              }}  to={Paths.main}
                  className='d-flex align-items-center justify-content-between' >
                    {login} 
                    <LogoUser style={{position:'relative', left:'10%'}}/>
              </NavLink>
              
            </Nav.Item>
          </Nav>
      </Navbar>
    );
  };
  
  export default NavbarPatients;