import { Alert } from 'react-bootstrap';
import React from "react";
import { alert_css } from './style';

type Props = {
  message?: string;
};

export const ErrorMessage = ({ message }: Props) => {
  if (!message) {
    return null;
  }

  return <Alert key='danger' variant='danger' style={alert_css}>{message}</Alert>;
};