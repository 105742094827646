import React from 'react';
import { ReactComponent as EditSvgComponent } from '../../select/edit_button_journal.svg';
import { ReactComponent as EditSvgComponentHover } from '../../select/edit_button_journalHover.svg';

const EditSvg: React.FC<{ width?: string;
                          height?: string;
                          type? : string
                         }>
                     = ({ width = "25", height = "24", type= 'black'}) => {
    if (type === 'black') {
        return  <EditSvgComponent width={width} height={height} />;
    } else if (type === 'hover') {
        return <EditSvgComponentHover width={width} height={height} />;
    }
    return null;
};

export default EditSvg;