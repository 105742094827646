import {getProcentWidth,getProcentHeight} from '../../../../../../assets/stylesVar'

const containerStyles: React.CSSProperties = {
    width:getProcentWidth(150),
    height:getProcentHeight(280),
    borderRadius:'25px',
    background:'#f5f5f7',
    borderColor:'#C8C8CC',
    borderWidth:'1px',
    borderStyle:'solid',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
}

const containerSmallStylesNotFocused : React.CSSProperties = {
    width:getProcentWidth(147),
    height:getProcentHeight(215),
    backgroundColor:'#FBFBFD',
    borderRadius:'25px',
    borderColor:'#C8C8CC',
    borderWidth:'1px',
    borderStyle:'solid',
    borderLeft:'none',
    borderRight:'none',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'space-around',
    position:'relative',
    top:'24%',
    transition: 'all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s',
    
}
const containerSmallStylesFocused : React.CSSProperties = {
    width:getProcentWidth(147),
    height:getProcentHeight(215),
    backgroundColor:'#FBFBFD',
    borderRadius:'25px',
    borderColor:'#C8C8CC',
    borderWidth:'1px',
    borderStyle:'solid',
    borderLeft:'none',
    borderRight:'none',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'space-around',
    position:'relative',
    top:'-1%',
    transition: 'all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s'
}

const ButtonChangeStyle : React.CSSProperties = {
    width:getProcentWidth(101),
    height:getProcentHeight(34),
    backgroundColor:'#070708',
    borderRadius:'10px',
    borderColor:'#070708',
    marginTop:'16px'

}

const CurrentDateStyleBot: React.CSSProperties = {
    width:getProcentWidth(150),
    height:getProcentHeight(217),
    background:'linear-gradient(180deg, rgba(51,111,238,1), rgba(173,196,255,1))',
    borderRadius:'25px',
    borderColor:'#C8C8CC',
    borderWidth:'1px',
    borderStyle:'solid',
    borderLeft:'none',
    borderRight:'none',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'space-around',
    position:'relative',
    top:'-1%',
    transition: 'all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s'
}

const CurrentDateStyleTop: React.CSSProperties = {
    width:getProcentWidth(150),
    height:getProcentHeight(217),
    background:'linear-gradient(180deg, rgba(51,111,238,1), rgba(173,196,255,1))',
    borderRadius:'25px',
    borderColor:'#C8C8CC',
    borderWidth:'1px',
    borderStyle:'solid',
    borderLeft:'none',
    borderRight:'none',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'space-around',
    position:'relative',
    top:'24%',
    transition: 'all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s'
}

const ItemDateStyleCurrent: React.CSSProperties = {
    fontWeight:'400',
    fontSize:'18px',
    color:'#FFFFFF'
}

const ItemDateStyle: React.CSSProperties = {
    fontWeight:'400',
    fontSize:'18px',
    color:'#336FEE'
}

const CompletionStyleCurrent: React.CSSProperties = {
    color:'#FFFFFF'
}

const CompletionStyle: React.CSSProperties = {
    color:'#336FEE'
}

const dayOfWeekStyleCurrent: React.CSSProperties = {
    color:'#FFFFFF',
    fontWeight:'600',
    fontSize:'18px'
}

const dayOfWeekStyle: React.CSSProperties = {
    color:'#333333',
    fontWeight:'600',
    fontSize:'18px'
}

export { 
    containerStyles,
    containerSmallStylesNotFocused,
    containerSmallStylesFocused,
    ButtonChangeStyle,
    CurrentDateStyleBot,
    CurrentDateStyleTop,
    ItemDateStyleCurrent,
    ItemDateStyle,
    CompletionStyleCurrent,
    CompletionStyle,
    dayOfWeekStyleCurrent,
    dayOfWeekStyle
  };