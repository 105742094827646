import React, { useState } from 'react';
import { containerQuest, containerStyles, sp_1 } from './questStyle';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const QuestForm: React.FC = () => {

    const navigate = useNavigate();


    const [sp1T] = useState('Анкета');

    const handlePatientOptionSelected = () => {
        navigate("/quest/patientquest");
    };

    const handleDoctorOptionClick = () => {
        navigate("/quest/doctorquest");
    };

    return(
        <div className="d-flex align-items-center justify-content-center" style={containerStyles}>
            <div style={containerQuest}>
                <div style={sp_1}>
                    {sp1T}
                </div>

                <div>
                    <div className='d-flex flex-column justify-content-around' style={{width:'400px', height:'150px'}}>
                    <h2 className='text-center' style={{fontSize:'24px'}}>Выберите вашу роль:</h2>
                        <div className='d-flex align-items-center justify-content-around'>
                            <Button onClick={handlePatientOptionSelected} style={{width:'160px'}}>Пациент</Button>
                            <Button onClick={handleDoctorOptionClick} style={{width:'160px'}}>Врач</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestForm;