import { api } from '../api';

interface iUserId {
  userId: string
}

interface iResponseCheck {
  welcomepage: String,
  Agreement: String
}
export const firstseeWApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendfData: builder.mutation<Response, iUserId>({
      query: (formData) => ({
        url: '/ut/firstsee',
        method: 'POST',
        body: formData,
      }),
    }),
    checkFirstSeeWelcomeData: builder.mutation<iResponseCheck, iUserId>({
      query: (formData) => ({
        url: '/ut/chkfsuser',
        method: 'POST',
        body: formData,
      }),
    }),
    checkAgreementApi: builder.mutation<iResponseCheck, iUserId>({
      query: (formData) => ({
        url: '/ut/chkagreem',
        method: 'POST',
        body: formData,
      }),
    }),
    agreementModal: builder.mutation<Response, iUserId>({
      query: (formData) => ({
        url: '/ut/firstagreem',
        method: 'POST',
        body: formData,
      }),
    })
  }),
});

export const { useSendfDataMutation,
   useCheckFirstSeeWelcomeDataMutation,
   useCheckAgreementApiMutation,
   useAgreementModalMutation } = firstseeWApi;

