import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAgreementModalMutation } from '../../../../../../app/services/utils_services/firstsee';

const AgreementForm: React.FC = () => {

    const [showM, setShowM] = useState(true);

    const [agreementModalApi] = useAgreementModalMutation();

    const [utFUserId] = useState({
        userId: localStorage.getItem('userId') || '0'
    });

    const handleClose = async () => {

        try {

           await agreementModalApi(utFUserId).unwrap()

        } catch (error) {
            setShowM(true)
        }
        setShowM(false)
    };

return (
<Modal size='xl' show={showM} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header>
            <Modal.Title><div>Vitaent - это Автоматизированная Информационная Система по контролю заболевания</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='flex-column'>
                <div>
                    <div>Система, с помощью информационных технологий, поможет пациентам найти свой индивидуальный подход к лечению болезни. Отслеживать важные факторы поведения болезни. Поможет врачу оперативно реагировать на них для улучшения состояния пациента.</div>
                    <br></br>
                    <div>Наладить процесс взаимодействия Врача и Пациента в одном информационном пространтсве. Продвинуть анализ болезни для врача на новый уровень для поиска лечения.</div>
                    <br></br>
                    <div>С Vitaent человек не останется один на один с болезнью.</div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className='d-flex justify-content-between' style={{width:'100%'}}>
            <label style={{fontSize:'15px',color: '#838384'}} >Нажимая "Продолжить", я принимаю условия Пользовательского соглашения и Политики обработки персональных данных</label>
            <Button variant="primary" onClick={handleClose}>
                Продолжить
            </Button>
            </div>
        </Modal.Footer>
        </Modal>
)
}        

export default AgreementForm;