import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ButtonNewRecStyle, CardStyles, DatePickerDivStyles, containerStyles } from './mainStyles';
import WeekPicker from '../../../../../../components/DatePickerWeekly/DatePickerWeekly';
import dayjs, { Dayjs } from 'dayjs';
import { NavLink } from 'react-router-dom';


interface iDataCard {
  dayOfWeek: String,
  date: String,
  currentDay: Boolean,
  morningValue: number,
  dailyValue: number,
  eveningValue: number
}


const Emotions: React.FC = () => {
  const [dataCheck, setDataCheck] = useState<Dayjs | null>(dayjs());

  const handleData = (data: Dayjs | null) => {
    setDataCheck(data);
  };

  const [daysWeekData,setDaysWeekData] = useState<iDataCard[]>([]);

  const CalcCardData = (): iDataCard[] => {
    
    const data: iDataCard[] = [];
        if (dataCheck) {
            var lData : Dayjs = dataCheck?.locale('ru').endOf('week');
            let i = dataCheck?.locale('ru').startOf('week');

            while (i && i.isBefore(lData)) {
                
                data.push({
                    dayOfWeek: i.locale('ru').format('dddd').charAt(0).toUpperCase() + i.locale('ru').format('dddd').slice(1),
                    date: i.locale('ru').format('DD.MM.YYYY'),
                    currentDay: (i.format('DD.MM.YYYY') === dayjs().locale('ru').format('DD.MM.YYYY')),
                    morningValue: 0,
                    dailyValue: 0,
                    eveningValue: 0
                });

                i = i.add(1, 'day');
            }
        }
        return data;
    };

  // Обновление данных при изменении value
      useEffect(() => {
        let DataCardCalc=CalcCardData();
        setDaysWeekData(DataCardCalc);
    // eslint-disable-next-line
    }, [dataCheck]);

  return (
    <div style={{ width: '100%' }}>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ backgroundColor: '#eeedef', height: '100%', width: '100%' }}
      >
        <Grid container spacing={2} 
              style={{ height: '90vh', padding: 16 }}
              alignItems="center"
        >
          <Grid item xs={12} justifyContent="center" display="flex">
            <Card style={containerStyles}>
              <div style={DatePickerDivStyles}>
                <WeekPicker onData={handleData} />
              </div>

              <CardContent>
                <Grid container
                      spacing={2}
                      columns={14}
                >

                  {daysWeekData.map((item, index) => (

                  <Grid key={index} item xs={12} sm={6} md={2}>
                    <Card sx={CardStyles}>
                      <CardContent sx={{display:"flex", justifyContent:"space-between", flexDirection:"column", height:'280px', alignItems:'center'}}>
                        <Typography variant="h5" component="div" justifyContent="center" display="flex">
                          {item.date}
                        </Typography>
                        <Typography variant="body2">
                          Утром
                        </Typography>
                        <Typography variant="body2">
                          Днем
                        </Typography>
                        <Typography variant="body2">
                          Вечером
                        </Typography>

                        <NavLink style={ButtonNewRecStyle} 
                        to={'EmotNewRec/'}
                        state={{pDate: item.date}}
                        >
                          Добавить
                        </NavLink>

                      </CardContent>
                    </Card>
                  </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Emotions;