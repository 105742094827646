import React, { useEffect, useState } from 'react';
import AgreementForm from './modalAgreement/Agreement';
import { useCheckAgreementApiMutation } from '../../../../../app/services/utils_services/firstsee';
import MainFormPatient from '../../modules/main/main'

const MainContent: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [checkAgreementApi] = useCheckAgreementApiMutation();
    const nUserId = localStorage.getItem('userId')||'';


    useEffect(() => {
        const fCheckModuleAgreement = async () => {
            try {
                const response = await checkAgreementApi({ userId: nUserId }).unwrap();
                setShowModal(response.Agreement === 'No');
            } catch (error) {
                setShowModal(false);
            }
        };

        fCheckModuleAgreement();
    // eslint-disable-next-line
    }, []);

    return (
        <div style={{width:'100%'}}>
        {showModal ? <AgreementForm /> : null}
        <MainFormPatient />
        </div>
        
    )
};

export default MainContent;