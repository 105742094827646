import {getWidth,getHeight} from '../../../../assets/stylesVar'


const containerStyles: React.CSSProperties = {
    width:getWidth(250),
    height:'100vh',
    backgroundColor:'#F5F5F7',
    border:'1px',
    borderStyle:'solid',
    borderColor:'#C8C8CC',
    borderTop:'none'
}

const SideBarLinkDiv : React.CSSProperties = {
    height: getHeight(40),
    textDecoration: 'none'
}

const SideBarLinkDivHover: React.CSSProperties = {
  height: getHeight(40),
  backgroundColor:'#EEEDEF',
  textDecoration: 'none',
};

const SideBarLinkDivActive : React.CSSProperties = {
  height: getHeight(40),
  backgroundColor:'#EEEDEF',
  textDecoration: 'none',
  border:'1px',
  borderStyle:'solid',
  borderColor:'#C8C8CC',
  borderLeft:'none',
  borderRight:'none',
};

const SideBarLabelText : React.CSSProperties = {
  fontSize: '18px',
  marginLeft: '10px',
  color: '#333333'
};

export { 
    containerStyles,
    SideBarLinkDivHover,
    SideBarLinkDiv,
    SideBarLinkDivActive,
    SideBarLabelText
  };