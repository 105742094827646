import { api } from '../../api';
import { PatientNotes } from ".prisma/client";


export type PatientNotesData = Omit<PatientNotes, "createdAt" | "updatedAt">


interface RequestParams {
    userId?: string;
    recDate?: Date;
    Label?: string;
    Entry?: string;
    TypeNoteId?: number;
    NoteId?: number;
}

interface RequestParamDel {
    noteId : number
};

export const journalApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getRecJournalNotes: builder.mutation<JSON, RequestParams>({
            query:  ( data ) => ({
                url: `/patient/journal/getAllJourRecs`,
                method: 'POST',
                body: data
            }),
        }),
        delRecJournalNotes: builder.mutation<void, RequestParamDel>({
            query:  ( data ) => ({
                url: `/patient/journal/delRecNote`,
                method: 'POST',
                body: data
            }),
        }),
        getJournalRecById: builder.mutation<PatientNotes, RequestParamDel>({
            query:  ( data ) => ({
                url: `/patient/journal/getRecById`,
                method: 'POST',
                body: data
            }),
        }),
        saveJournalRecById: builder.mutation<void, RequestParams>({
            query:  ( data ) => ({
                url: `/patient/journal/saveRecById`,
                method: 'POST',
                body: data
            }),
        }),
    }),
});

export const { 
    useGetRecJournalNotesMutation,
    useDelRecJournalNotesMutation,
    useGetJournalRecByIdMutation,
    useSaveJournalRecByIdMutation
} = journalApi;