import React from 'react';

const containerStyles: React.CSSProperties = {
  top: '25%',
  position: 'absolute',
  width: '100%',
};

const containerQuest: React.CSSProperties = {
    width:"500px",
    height:"600px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    backgroundColor:"#FCFCFC",
    justifyContent: "space-evenly",
    boxShadow: "0px 0px 50px rgba(51,111,238,0.15)",
    borderRadius: "25px"
  };

const sp_1: React.CSSProperties = {
    color:"1E1E20",
    fontSize:"36px",
    
  };

export { 
    containerStyles,
    containerQuest,
    sp_1
  };