import React, { useEffect, useState } from 'react';
import { ButtonInfoStyle, ButtonNewRecStyle, ButtonSendDocStyle, ContainerStyle, DatePickStyles, ItemTagStyles, SvgDeleteStylesNotActive, SvgEditStylesNotActive, TagIndexStyle, TextInfoBotStyle, TextMainStyle, TimeIndexNotActiveStyle, TiteCardArrayStyles, TitleCardArrayItemStyles, TitleStyles, containerStyles } from './journalStyles';
import DateTimePickerForm from '../../../../../../components/DateTimePicker/DateTimePicker';
import { Button } from 'react-bootstrap';
import DeleteSvg from '../../../../../../assets/img/journal/components/delete/Delete';
import EditSvg from '../../../../../../assets/img/journal/components/edit/Edit';
import { NavLink } from 'react-router-dom';
import { PatientNotes } from ".prisma/client";
import { useGetTypeNotesQuery } from '../../../../../../app/services/patient/journal/journalentry/typenotes';
import { useDelRecJournalNotesMutation, useGetRecJournalNotesMutation } from '../../../../../../app/services/patient/journal/journal';
import dayjs, { Dayjs } from 'dayjs';

export type PatientNotesData = Omit<PatientNotes,"createdAt" | "updatedAt">

interface iTypeNote {
    id: number;
    Code: string;
    Name: string;
    Explain: string;
}

interface RequestParams {
    userId: string;
    recDate: Date
}

interface RequestParamDel {
    noteId : number
};

const JournalFormPatient: React.FC = () => {

    const userId = localStorage.getItem('userId') || '0';
    const [getRecPatinentJour] = useGetRecJournalNotesMutation();
    const [delRecPatientJour]  = useDelRecJournalNotesMutation();
    const getTypeNotesQuery = useGetTypeNotesQuery();
    const [TitleCardArray,setTitleCardArray] = useState<PatientNotesData[]>([]);
    const [rData, setrData] = useState<iTypeNote[] | undefined>();
    const [dataCheck,setDataCheck] = useState<Dayjs|null>(dayjs());
    const [editIndex,setEditIndex] = useState(0);
    const [deleteIndex,setDeleteIndex] = useState(0);
    
    const removeTitleCardById = (idToRemove: number) => {
            
        const updatedArray = TitleCardArray.filter(item => item.id !== idToRemove);
        
        const cParamDel : RequestParamDel = {noteId : idToRemove};

        delRecPatientJour(cParamDel);
        setTitleCardArray(updatedArray);

    };

    function formatTime(time: Date) {

        const dTime = new Date(time);

        return dTime.getHours() + ':' + dTime.getMinutes().toString().padStart(2, '0'); // Например, формат 'часы:минуты'
    }

    const getTypeDict = (TypeId : number) => {

        if (rData)
        {
            return (rData.find(x => x.id === TypeId)?.Name);
        }
        
    }

    const handleData = (data: Dayjs|null) => {
        setDataCheck(data);
      };

    const sendToDoctor = () => {
        console.log(TitleCardArray);
    }
    
    const setEditCheckActiveIndex = (pIndex : number) =>
    {   
            setEditIndex(pIndex);
    }

    const setDeleteCheckActiveIndex = (pIndex : number) =>
    {
            setDeleteIndex(pIndex);
    }


    useEffect(() => {

        const fGetRecPatNotes = async () => {
            let lData;
            try {

                const data: RequestParams = {
                    userId: userId,
                    recDate: dataCheck ? dataCheck.toDate() : dayjs().toDate()
                };

                await getRecPatinentJour(data).unwrap().then((result => {lData = result}));
                

                return JSON.parse(JSON.stringify(lData));
                
                
            } catch (error) {
                console.log(error);
            }
        };
    
        fGetRecPatNotes().then(result => {setTitleCardArray(result)});
        // eslint-disable-next-line
    }, [getRecPatinentJour,dataCheck]);          

    useEffect(() => {
        const fGetTypeNotes = async () => {
            try {
                if (getTypeNotesQuery.isSuccess) {
                    const responseData = JSON.parse(getTypeNotesQuery.data.toString()); 
                    setrData(responseData);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fGetTypeNotes();
    // eslint-disable-next-line
    }, [getTypeNotesQuery.data]);

    return (
        <div style={{width:'100%'}}>
        <div className='d-flex align-items-center justify-content-center ' 
             style={{backgroundColor:'#eeedef',height:'100%',width:'100%'}}>
            <div style={containerStyles}>
                <div style={ContainerStyle}>
                    <div style={TextMainStyle}>
                        Журнал
                    </div>
                    <div style={DatePickStyles}>
                        <DateTimePickerForm onTime={handleData}/>
                    </div>
                    <div style={TiteCardArrayStyles}>
                    {
                        TitleCardArray.map((item,index) => (
                            <div key={index} style={TitleCardArrayItemStyles}>
                                <div style={TitleStyles}>
                                    {item.Label}
                                </div>
                                <div style={ItemTagStyles}>
                                        <div style={TagIndexStyle}>
                                            {getTypeDict(item.TypeNote)}
                                        </div>
                                </div>
                                <div style={TimeIndexNotActiveStyle}>
                                    {formatTime(item.RecDate)}
                                </div>
                                <NavLink style={SvgEditStylesNotActive} 
                                         to={'JournalEntryRec/ChangeRec'}
                                         state={{pRecId:item.id}}
                                         onMouseOver={()=>setEditCheckActiveIndex(item.id)}
                                         onMouseOut={()=>setEditCheckActiveIndex(0)}
                                >
                                    {
                                        item.id === editIndex ? <EditSvg type="hover"/> : <EditSvg type="black"/> 
                                    }
                                </NavLink>
                                <div    style={SvgDeleteStylesNotActive} 
                                        onClick={()=>removeTitleCardById(item.id)}
                                        onMouseOver={()=>setDeleteCheckActiveIndex(item.id)}
                                        onMouseOut={()=>setDeleteCheckActiveIndex(0)}>
                                    {
                                        deleteIndex === item.id ? <DeleteSvg type="hover"/> : <DeleteSvg type="black"/>
                                    }
                                </div>
                            </div>
                        )
                        ) 
                    }
                    </div>
                    <div style={{height:'10px'}}>
                    </div>
                </div>
                <div style={ButtonInfoStyle}>
                    <div>
                        <div style={TextInfoBotStyle}>
                            Вы можете редактировать заметки
                        </div>
                        <div style={TextInfoBotStyle}>
                            журнала после отправки врачу
                        </div>
                    </div>
                
                    <NavLink style={ButtonNewRecStyle} 
                        to={'JournalEntryRec/newEntry'}
                        state={{pDate: dataCheck?.toDate()||dayjs().toDate()}}
                    >
                        Новая запись
                    </NavLink>
                    <Button style={ButtonSendDocStyle} onClick={sendToDoctor}>
                        Отправить врачу
                    </Button>
                </div>
            </div>
        </div>
        </div>
            
        
    );
}

export default JournalFormPatient;