import {getProcentWidth, getProcentHeight} from '../../../../../../assets/stylesVar'

const containerStyles: React.CSSProperties = {
    width:getProcentWidth(1400),
    height:getProcentHeight(820),
    borderRadius:'25px',
    background:'#FBFBFD',
    display:'flex',
    alignItems:'center',
    flexDirection:'column',
    justifyContent:'center'
}
const TextMainStyle: React.CSSProperties = {
    fontSize:'15px',
    color:'#838384',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position:'relative',
    top:'5%'
}

const ContainerStyle: React.CSSProperties = {
    display:'flex',
    flexDirection: 'column',
    height:'100%',
    width:'100%',
    alignItems:'center'
}

const DatePickStyles: React.CSSProperties = {
    position: 'relative',
    top: '10%',
    left:'35%'
}

const TiteCardArrayStyles : React.CSSProperties = {
    position: 'relative',
    top: '20%',
    width:getProcentWidth(1182),
    height:getProcentHeight(470),
    overflowY:'auto',
}

const TitleCardArrayItemStyles : React.CSSProperties = {
    width: getProcentWidth(1182),
    height: getProcentHeight(58),
    backgroundColor:'#F5F5F7',
    borderRadius:'10px',
    marginBottom:'10px',
    display:'flex',
    alignItems:'center'

}
const TitleStyles : React.CSSProperties = {
    position:'relative',
    left:'10%',
    width: getProcentWidth(150)
}

const ItemTagStyles : React.CSSProperties = {
    position:'relative',
    left:'10%',
    width: '20vw',
    display:'flex'
}

const TagIndexStyle : React.CSSProperties = {
    position:'relative',
    left:'30%',
    width:'10vw',
    height:getProcentHeight(28),
    background:'linear-gradient(90deg, rgba(251,251,253,1), rgba(238,237,239,1))',
    borderRadius: '25px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    color:'#838384',
    border:'1px',
    borderStyle:'solid'
}
const TimeIndexNotActiveStyle: React.CSSProperties = {
    position:'relative',
    left:'40%',
    width: getProcentWidth(80),
    height:getProcentHeight(38),
    backgroundColor:'#C8C8CC',
    borderRadius: '25px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    color:'#FFFFFF'

}

const SvgEditStylesNotActive: React.CSSProperties = {
    position:'relative',
    left:'42%'
}

const SvgDeleteStylesNotActive: React.CSSProperties = {
    position:'relative',
    left:'43%',
}

const TextInfoBotStyle: React.CSSProperties = {
    fontSize:'15px',
    color:'#838384',
    right:getProcentWidth(74),
    position:'relative'
}

const ButtonNewRecStyle: React.CSSProperties = {
    backgroundColor:'#336FEE',
    borderRadius:'10px',
    width: getProcentWidth(138),
    height:getProcentHeight(36),
    right:getProcentWidth(46),
    position:'relative',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    color:'#FFFFFF',
    textDecoration:'none'
}

const ButtonSendDocStyle: React.CSSProperties = {
    backgroundColor:'#070708',
    borderRadius:'10px',
    width: getProcentWidth(139),
    height:getProcentHeight(36),
    position:'relative'
}

const ButtonInfoStyle : React.CSSProperties = {
    display:'flex',
    width:getProcentWidth(1182),
    justifyContent:'end',
    alignItems:'center',
    height:getProcentHeight(1182),

}
export { 
    containerStyles,
    TextMainStyle,
    ContainerStyle,
    DatePickStyles,
    TiteCardArrayStyles,
    TitleCardArrayItemStyles,
    TitleStyles,
    ItemTagStyles,
    TagIndexStyle,
    TimeIndexNotActiveStyle,
    SvgDeleteStylesNotActive,
    SvgEditStylesNotActive,
    TextInfoBotStyle,
    ButtonNewRecStyle,
    ButtonSendDocStyle,
    ButtonInfoStyle
  };