import { api } from '../api';

interface iFormDataQuestDoctors {
    userId: String,
    spec: String,
    exp: String,
    age: String,
    gender: String
}

export const doctorQuestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendDData: builder.mutation<Response, iFormDataQuestDoctors>({
      query: (formData) => ({
        url: '/quest/questDoctors',
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const { useSendDDataMutation } = doctorQuestApi;