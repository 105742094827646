import {getWidth,getHeight} from '../../assets/stylesVar'

const CalendarPickerStyle: React.CSSProperties = {
    width:getWidth(60),
    height:getHeight(35.09),
    borderRadius:'10px',
    backgroundColor:'#F5F5F7'
}

export { 
    CalendarPickerStyle
}    