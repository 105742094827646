import {    getProcentHeight,
            getProcentWidth
        } from "../../../../../../assets/stylesVar";

const CardStylesF: React.CSSProperties = {
    borderRadius:'25px',
    background:'#FBFBFD',
    borderColor:'#FBFBFD',
    borderWidth:'1px',
    borderStyle:'solid',
    boxShadow: 'none',
    color:'#838384',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    minWidth:getProcentWidth(430),
    minHeight:getProcentHeight(330),
  };

  const CardStylesF2: React.CSSProperties = { 
    background:'#fbfbfd',
    borderColor:'#fbfbfd',
    borderRadius:'25px',
    borderWidth:'1px',
    borderStyle:'solid',
    boxShadow: 'none',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    marginTop:'55px',
    minWidth:getProcentWidth(350),
    minHeight:getProcentHeight(250),
   }

  const CardStylesS: React.CSSProperties = {
    borderRadius:'25px',
    background:'#f5f5f7',
    borderColor:'#f5f5f7',
    borderWidth:'1px',
    borderStyle:'solid',
    boxShadow: 'none',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-evenly',
    alignItems:'center',
    marginLeft:'10px',
    minWidth:getProcentWidth(350),
    minHeight:getProcentHeight(330),
  };

  const CardStylesSButton : React.CSSProperties = {
    borderRadius:'25px',
    background:'#f5f5f7',
    borderColor:'#f5f5f7',
    borderWidth:'1px',
    borderStyle:'solid',
    boxShadow: 'none',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-evenly',
    alignItems:'center',
    marginLeft:'10px',
    minWidth:getProcentWidth(350),
  };

  const containerStyles: React.CSSProperties = {
    width:getProcentWidth(1383),
    height:getProcentHeight(560),
    borderRadius: '25px',
    background: '#FBFBFD',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  };

  export { 
    CardStylesF,
    CardStylesS,
    CardStylesF2,
    containerStyles,
    CardStylesSButton
   };