import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BaseSingleInputFieldProps, DatePicker, DateValidationError, FieldSection, UseDateFieldProps } from '@mui/x-date-pickers';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import 'dayjs/locale/ru'
import { CalendarPickerStyle } from './DatePickerWeeklyStyles';
import { ReactComponent as DatePickerSvg} from '../../assets/img/DatePicker/DatePickerWeekly.svg';
import { ReactComponent as LineDatesSvg} from '../../assets/img/sidebar/Line.svg';
import { useEffect, useState } from 'react';


dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isHovered: boolean;
}

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 1 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 0 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, 'week');
};

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null;
    hoveredDay?: Dayjs | null;
  },
) {
  const { day, selectedDay, hoveredDay, ...other } = props;
  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    id,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;

  return (
    <div className='d-flex justify-content-center align-items-center' style={CalendarPickerStyle}>
      <DatePickerSvg 
        id={id}
        ref={ref}
        aria-label={ariaLabel}
        onClick={() => setOpen?.((prev) => !prev)}
      />
    </div>
  );
}

  interface ChildProps {
    onData: (data: Dayjs| null) => void; // Определение типа для пропса onData
  }

  const WeekPicker: React.FC<ChildProps> = ({ onData }) => {

    

  const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());
  const [open, setOpen] = useState(false);
  
    // Обновление данных при изменении value
  useEffect(() => {
    onData(value); // Вызываем функцию onData с новым значением value
  }, [value, onData]);

  const setValueCloseF = (pValue: React.SetStateAction<dayjs.Dayjs | null>) => {
    setValue(pValue);
    setOpen(false);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
      <DatePicker
        value={value}
        onChange={(newValue) => setValueCloseF(newValue)}
        showDaysOutsideCurrentMonth
        closeOnSelect
        open={open}
        slots={{ day: Day, field:ButtonField }}
        slotProps={{
          day: (ownerState) =>
            ({
              selectedDay: value,
              hoveredDay,
              onPointerEnter: () => setHoveredDay(ownerState.day),
              onPointerLeave: () => setHoveredDay(null),
            }) as any,
          field: { setOpen } as any,
        }}
      />
      <div style={{color:'#333333',fontWeight:'600'}}>{value?.locale('ru').startOf('week').format('DD.MM.YYYY')}</div>
      <LineDatesSvg/>
      <div style={{color:'#333333',fontWeight:'600'}}>{value?.locale('ru').endOf('week').format('DD.MM.YYYY')}</div>      
    </LocalizationProvider>
  );

}


export default WeekPicker;