import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ErrorMessage } from '../../components/error-message';
import { b_login, containerAuth, containerStyles, log_in, pass_in, sp_1, sp_2 } from './registerStyle';
import { useRegisterMutation } from "../../app/services/auth";
import { useNavigate } from 'react-router';
import { isErrorWithMessage } from '../../utils/is-error-with-message';

export const RegisterForm = () => {

    const [registerUser] = useRegisterMutation();
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const [formData, setFormData] = useState({ login: "", password: "", role: "undefined"});

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

    const backLogin = () => {
        navigate("/")
    }
    

    const register = async () => {
        try {
          await registerUser(formData).unwrap();
    
          navigate("/");
        } catch (err) {
          const maybeError = isErrorWithMessage(err);
    
          if (maybeError) {
            setError(err.data.message);
          } else {
            setError("Неизвестная ошибка");
          }
        }
      };


    return (
        <div className="d-flex align-items-center justify-content-center" style={containerStyles}>
            <div style={containerAuth}>
                <div className='d-flex flex-column'>
                <span style={sp_1}>Регистрация</span>
                <span style={sp_2}>Придумайте логин и пароль</span>
                </div>
            <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control name="login" type="text" placeholder="Логин" style={log_in} onChange={handleInputChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control name="password" type="password" placeholder="Пароль" style={pass_in} onChange={handleInputChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control name="confirmPassword" type="password" placeholder="Подтверждение Пароля" style={pass_in} onChange={handleInputChange}/>
                </Form.Group>
            </div>
            <div style={{ display: "flex",
                          flexDirection: "column",
                          height: "108px",
                          justifyContent: "space-around"
                        }}>
                <Button variant="primary" style={b_login} onClick={register}>
                     Зарегистрироваться
                </Button>
                <Button variant="primary" style={b_login} onClick={backLogin}>
                     Назад
                </Button>
            </div>
            <ErrorMessage message={error}/>
        </div>
      </div>
    );
};

export default RegisterForm;
