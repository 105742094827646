const containerStyles: React.CSSProperties = {
    borderWidth:'1px',
    borderStyle:'solid',
    borderColor:'#C8C8CC',
    backgroundColor:'#F5F5F7',
    height:'56px'
}

  export { 
    containerStyles
  };