import * as React from 'react';
import 'dayjs/locale/ru'
import { DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { ReactComponent as DatePickerSvg} from '../../assets/img/DatePicker/DatePickerWeekly.svg';
import { useEffect } from 'react';

interface ChildProps {
    onTime: (data: Dayjs| null) => void; // Определение типа для пропса onData
  }


const DateTimePickerForm: React.FC<ChildProps> = ({ onTime }) => {

    const [value, setValue] = React.useState<Dayjs | null>(dayjs());

    useEffect(() => {
        onTime(value); // Вызываем функцию onData с новым значением value
     }, [value, onTime]);

    return (
            
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
                <DatePicker 
                    defaultValue={dayjs()} 
                    slots={{openPickerIcon:DatePickerSvg}}
                    onChange={(newValue) => setValue(newValue)}
                />
            </LocalizationProvider>
    );
    
}


export default DateTimePickerForm;