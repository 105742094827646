export const Paths = {
    main:'lk/',
    Journal:'Journal/',
    DailyRoutine:'DailyRoutine/',
    Drugs:'Drugs/',
    Disease:'Disease/',
    JournalTitleCardRecord:'Journal/JournalEntryRec/newEntry',
    JournalEntryRecord: 'Journal/JournalEntryRec/ChangeRec',
    EmotionsMain: 'Emotions/',
    EmotionEntryRec: 'Emotions/EmotNewRec/',
} as const;
