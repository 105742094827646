import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Paths } from './paths';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/login/login';
import RegisterForm from './pages/register/register';
import QuestForm from './pages/quest/quest';
import PatientQuestForm from './pages/quest/patienquest/patientquest';
import WelcomePageForm from './pages/welcome/welcomePage';
import DoctorQuestForm from './pages/quest/doctorquest/doctorquest';
import AppPatients from './pages/main_app/patient/AppPatients';

const router = createBrowserRouter([
  {
    path: Paths.login,
    element: <Login/>
  },
  {
    path: Paths.register,
    element: <RegisterForm/>
  },
  {
    path: Paths.quest,
    element: <QuestForm/>
  },
  {
    path: Paths.questpatient,
    element: <PatientQuestForm />
  },
  {
    path: Paths.welcomePage,
    element: <WelcomePageForm />
  },
  {
    path: Paths.questdoctor,
    element: <DoctorQuestForm />
  },
  {
    path: Paths.patientMain,
    element: <AppPatients />
  }
]);
<RouterProvider router={router}/>

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
