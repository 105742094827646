import * as React from 'react';
import 'dayjs/locale/ru'
import { LocalizationProvider, TimePicker} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs,{ Dayjs } from 'dayjs';
import { useEffect } from 'react';

interface ChildProps {
    onTime: (data: Dayjs| null) => void; // Определение типа для пропса onData
  }

const TimePickerForm: React.FC<ChildProps> = ({ onTime }) => {

    const [value, setValue] = React.useState<Dayjs | null>(dayjs());

        // Обновление данных при изменении value
     useEffect(() => {
        onTime(value); // Вызываем функцию onData с новым значением value
     }, [value, onTime]);

    return (
            
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
                <TimePicker  
                    value={value}
                    onChange={(newValue) => setValue(newValue)} 
                />
            </LocalizationProvider>
    );
    
}


export default TimePickerForm;