import {getProcentWidth, getProcentHeight} from '../../../../../../assets/stylesVar'

const containerStyles: React.CSSProperties = {
    width:getProcentWidth(1400),
    height:getProcentHeight(820),
    borderRadius:'25px',
    background:'#FBFBFD',
    display:'flex',
    alignItems:'center',
    flexDirection:'column'
}

const TextMainStyle: React.CSSProperties = {
    fontSize:'15px',
    color:'#838384',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position:'relative',
    top:'5%'
}

const BackStyle: React.CSSProperties = {
    position:'relative',
    top:'5%',
    right:'45%',
    cursor: 'pointer'
}

const TimePickStyles: React.CSSProperties = {
    position: 'relative',
    top: '10%',
    left:'35%'
}

const TextInsertRecDiv: React.CSSProperties = {
    width:getProcentWidth(1000),
    height:getProcentHeight(450),
    marginTop:'4vw',
    display:'flex'
}

const ButtonNewRecStyle: React.CSSProperties = {
    backgroundColor:'#336FEE',
    borderRadius:'10px',
    width: getProcentWidth(138),
    height:getProcentHeight(36),
    right:getProcentWidth(46),
    position:'relative',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    color:'#FFFFFF',
    textDecoration:'none'
}



export { 
    containerStyles,
    TextMainStyle,
    BackStyle,
    TimePickStyles,
    TextInsertRecDiv,
    ButtonNewRecStyle
  };