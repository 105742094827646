import { api } from '../../../api'
import { PatientNotes } from ".prisma/client";

export type PatientNotesData = Omit<PatientNotes,"createdAt" | "updatedAt" | "id">


export const PatientCreateNoteApi = api.injectEndpoints({
    endpoints:(builder) => ({
      setPatientCreateNote: builder.mutation<Response,PatientNotesData>({
        query:(noteData) => ({
          url:'/patient/journal/recNJour',
          method:"POST",
          body:(noteData)
        })
      })
    })
  })

export const { useSetPatientCreateNoteMutation } = PatientCreateNoteApi;