import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSendfDataMutation } from '../../app/services/utils_services/firstsee';

const vPath = require('../../assets/Video/VitaentWelcome.mp4');

const WelcomePageForm: React.FC = () => {
    const navigate = useNavigate();
    const [sendData] = useSendfDataMutation();
    const [utFUserId, setUtFUserId] = useState({
        userId: ''
    });

    useEffect(() => {
        // Проверяем, что userId установлен и вызываем sendDataApi
        if (utFUserId.userId) {
            sendDataApi();
        }        
    // eslint-disable-next-line
    }, [utFUserId]); // Зависимость от utFUserId

    const sendDataApi = async () => {
        try {
            await sendData(utFUserId).unwrap();

            if (localStorage.getItem('role')==='Patient')
            {
                navigate('/main/P/lk');
            }
            else if (localStorage.getItem('role')==='Doctor')
            {
                navigate('/main/D/lk');
            }

            
        } catch (error) {
            console.log(error);
        }
    };

    const gotomain = () => {
        setUtFUserId(() => ({
            userId: localStorage.getItem('userId') || '0'
        }));
    }

    return (
        <div className='d-flex justify-content-center flex-column align-items-center'>
            <div className='d-flex align-items-center justify-content-around' style={{ width: '400px', marginTop: '40px' }}>
                <p style={{ fontSize: "26px" }}>Добро пожаловать в Vitaent</p>
            </div>
            <div style={{ marginTop: '30px' }}>
                <video width="1280px" height="720px" autoPlay loop controls playsInline muted style={{ borderRadius: '25px' }}>
                    <source src={vPath} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className='d-flex justify-content-between' style={{ width: '100%', maxWidth: '1280px' }}>
                <p style={{ fontSize: '18px', color: '#838384', width: '100%', maxWidth: '500px', marginTop: '40px' }}>
                    Вы можете остаться на данной представляющей странице,
                    или продолжить и приступить к работе
                </p>
                <Button
                    variant="primary"
                    style={{ width: '160px', height: '38px', marginTop: '40px' }}
                    onClick={gotomain}
                >
                    Продолжить
                </Button>
            </div>
        </div>
    );
};

export default WelcomePageForm;
