import { api } from '../api';

interface iFormDataQuestPatients {
  userId:  string,
  disease: string;
  duration: string;
  age: string;
  gender: string;
  doctorVisit: boolean;
  doctorRegistered: boolean;
}

export const patientQuestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendData: builder.mutation<Response, iFormDataQuestPatients>({
      query: (formData) => ({
        url: '/quest/questPatients',
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const { useSendDataMutation } = patientQuestApi;