import { api } from '../../api';
import { 
            PatientEmotion,
            DictDailyEmotionsState,
            DictDailyEmotionSect,
            PatientEmotionsStateVal,
            PatientEmotionsState,
            PatientEmotionsStateMemo
        } from ".prisma/client";

export type IPatientEmotion = Omit<PatientEmotion, "createdAt" | "updatedAt">

export type IDictEmotionsState = DictDailyEmotionsState;
export type IDictEmotionsSect = DictDailyEmotionSect;

export type IEmotionsState = PatientEmotionsState;
export type IEmotionsStateVal = PatientEmotionsStateVal;
export type IEmotionsStateMemo = PatientEmotionsStateMemo;

export interface IPatientEmotionArray {
    ArrayIndex: IPatientEmotion,
    patientEmotion: {
        patientEmotion:          IEmotionsState
        patientEmotionsStateVal: IEmotionsStateVal
        patientEmotionMemo:      IEmotionsStateMemo
    }[]
};

export const EmotionsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        saveEmotionsRec: builder.mutation<Response, IPatientEmotionArray>({
            query:  ( data ) => ({
                url: `/patient/emotions/recNEmot`,
                method: 'POST',
                body: data
            }),
        }),
        getDictDailyEmotionSect: builder.query<JSON,void>({
            query: () => ({
              url: '/ut/DailyEmotionSect',
              method: 'GET'
            }),
        }),
        getDictDailyEmotionState: builder.query<JSON,void>({
            query: () => ({
              url: '/ut/DailyEmotionState',
              method: 'GET'
            }),
        }),
        getPatientEmotionsbyDay: builder.mutation<JSON,IPatientEmotion>({
            query:  ( data ) => ({
                url: `/patient/emotions/recEmotByDay`,
                method: 'POST',
                body: data
            }),
        })
    }),
});

export const { 
    useSaveEmotionsRecMutation,
    useGetDictDailyEmotionSectQuery,
    useGetDictDailyEmotionStateQuery,
    useGetPatientEmotionsbyDayMutation
} = EmotionsApi;