const getWidth = (pWidth:number) => {

    return (((pWidth/1920)*document.documentElement.scrollWidth).toString()+'px');
}

const getHeight = (pHeight:number) => {
    return (((pHeight/1080)*document.documentElement.scrollHeight).toString()+'px');
}

const getProcentWidth = (pWidth:number) => {

    return (((pWidth/1920)*100).toString()+'vw');
}

const getProcentHeight = (pHeight:number) => {

    return (((pHeight/1080)*100).toString()+'vh');
}

const getProcentTop = (p_px:number) => {
    return (((p_px/1920)*100).toString()+'vw');
}

const getProcentBot = (p_px:number) => {
    return (((p_px/1920)*100).toString()+'vw');
}

const getProcentLeft = (p_px:number) => {
    return (((p_px/1080)*100).toString()+'vh');
}
const getProcentRight = (p_px:number) => {
    return (((p_px/1080)*100).toString()+'vh');
}

export  {
            getWidth,
            getHeight,
            getProcentWidth,
            getProcentHeight,
            getProcentTop,
            getProcentBot,
            getProcentLeft,
            getProcentRight
        };