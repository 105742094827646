import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SideBarLinkDiv, SideBarLinkDivHover, SideBarLinkDivActive, containerStyles, SideBarLabelText } from './sidebarStyle';
import { Paths } from '../../patient/PatientPaths';
import { ReactComponent as DailyRoutineSVG } from '../../../../assets/img/sidebar/DailyRoutine.svg'
import { ReactComponent as DiseaseSVG } from '../../../../assets/img/sidebar/Disease.svg'
import { ReactComponent as DrugsSVG } from '../../../../assets/img/sidebar/Drugs.svg'
import { ReactComponent as FeelingsSVG } from '../../../../assets/img/sidebar/Feelings.svg'
import { ReactComponent as SelfConditionSVG } from '../../../../assets/img/sidebar/SelfCond.svg'
import { ReactComponent as HomeSVG } from '../../../../assets/img/sidebar/user-octagonSide.svg'
import { ReactComponent as DailyRoutineBSVG } from '../../../../assets/img/sidebar/select/DailyRoutineBlue.svg'
import { ReactComponent as DiseaseBSVG } from '../../../../assets/img/sidebar/select/DiseaseBlue.svg'
import { ReactComponent as DrugsBSVG } from '../../../../assets/img/sidebar/select/DrugsBlue.svg'
import { ReactComponent as FeelingsBSVG } from '../../../../assets/img/sidebar/select/FeelingsBlue.svg'
import { ReactComponent as SelfConditionBSVG } from '../../../../assets/img/sidebar/select/SelfConditionBlue.svg'
import { ReactComponent as HomeBSVG } from '../../../../assets/img/sidebar/select/userBlue.svg'
import {getWidth,getHeight} from '../../../../assets/stylesVar'


const Sidebar: React.FC = () => {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [activeIndex, setActiveIndex] = useState(Number(localStorage.getItem('SidebarActiveIndex'))||0);

  const SideBarData = [
    {
      textLabel: 'Главная',
      iconSrc: '',
      to: Paths.main,
      section: 'Home',
      ComponentSvg: <HomeSVG />,
      SelectSvg: <HomeBSVG />
    },
    {
      textLabel: 'Журнал',
      iconSrc: '',
      to: Paths.Journal,
      section: '',
      ComponentSvg: <SelfConditionSVG />,
      SelectSvg: <SelfConditionBSVG />
    },
    {
      textLabel: 'Эмоции',
      iconSrc: '',
      to: Paths.EmotionsMain,
      section: '',
      ComponentSvg: <FeelingsSVG />,
      SelectSvg: <FeelingsBSVG />
    },
    {
      textLabel: 'Режим дня',
      iconSrc: '',
      to: Paths.DailyRoutine,
      section: '',
      ComponentSvg: <DailyRoutineSVG />,
      SelectSvg: <DailyRoutineBSVG />
    },
    {
      textLabel: 'Лекарства',
      iconSrc: '',
      to: Paths.Drugs,
      section: '',
      ComponentSvg: <DrugsSVG />,
      SelectSvg: <DrugsBSVG />
    },
    {
      textLabel: 'Болезнь',
      iconSrc: '',
      to: Paths.Disease,
      section: '',
      ComponentSvg: <DiseaseSVG />,
      SelectSvg: <DiseaseBSVG />
    },
  ];

  const handleMouseOver = (index: number) => {
    setHoverIndex(index);
  };

  const handleMouseOut = () => {
    setHoverIndex(-1);
  };

  const handleItemClick = (index: number) => {
    setActiveIndex(index);
    localStorage.setItem('SidebarActiveIndex',index.toString());
  };

  return (

    <div style={containerStyles}>
      <div className='d-flex flex-column justify-content-around align-items-center' style={{
        height: getHeight(350),
        marginTop: '16px'
      }}>
        {SideBarData.map((item, index) => (
          <div key={index} style={{width:getWidth(245)}}>
            <NavLink
               style={activeIndex === index ? SideBarLinkDivActive : (hoverIndex === index ? SideBarLinkDivHover : SideBarLinkDiv)}
               onMouseOver={() => handleMouseOver(index)}
               onMouseOut={handleMouseOut}
               onClick={() => handleItemClick(index)}
                to={item.to}
              className='d-flex align-items-center'>
              {
                activeIndex === index ? <div style={{width:getWidth(4), backgroundColor:'#1E82F5', height:'100%'}}></div> : null
              }
              <div style={{ marginLeft: '30px' }}>{activeIndex === index ? item.SelectSvg : item.ComponentSvg}</div>
              <label style={SideBarLabelText}>{item.textLabel}</label>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;