import { createReducer } from '@reduxjs/toolkit';
import { setUser } from '../actions/userActions';

interface UserState {
  id: string;
  login: string;
  role: string
}

const initialState: UserState = {
  id: '',
  login: '',
  role: ''
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUser, (state, action) => {
      state.id = action.payload.id;
      state.login = action.payload.login;
      state.role = action.payload.role;
    });
});